.App {
  text-align: center;
}

.FixItIcon {
  /*
  animation: App-logo-spin infinite 20s linear;
  animation-play-state: paused;*/
  height: 15vmin;
  pointer-events: none;
}

.AppHeader {
  background-color: #535b69;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.AppBody {
  background-color: #535b69;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AppFooter {
  background-color: #535b69;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fixItButton
{
  border-radius: 25px;
  padding: 10px;
  border: solid;
  color: white;
  text-decoration: none;
}

.AppLink {
  color: #61dafb;
}

.fixItButton:hover {
  border-width: 5px;
  color: rgb(0, 162, 255);
  background-color: #393f49;
  cursor: pointer;

}
/***Jobs List Css start***/
.invoiced{
	color: white; 
	background-color: #00569C;
	padding: 5px 15px;
	text-align: center;
	width: 100%;
	border-radius:4px;
	margin-left:-20px;
	font-size: 15px;
}
.complete{
	color: white; 
	background-color: #388E3C; 
	padding: 5px 15px;
	text-align: center;
	width: 100%;
	border-radius:4px;
	margin-left:-20px;
	font-size: 15px;
}
.progress{
	margin-left:-20px;
	color: white; 
	background-color: #D61A0E;
	padding: 5px 15px; 
	text-align: center;
	width: 100%;
	border-radius:4px;
	font-size: 15px;
}
.Job{
	color:black; 
	font-weight:bold;
	font-size:25px;
	position:absolute;
	margin-left:-6px;
}
.Alert{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
/**Jobs List Css end****/
/*
.fixItButton:hover .FixItIcon {
  animation: App-logo-spin infinite 20s linear;
}
*/
.logo
{
  height: 15vmin;
}
/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

a:hover{
  cursor: pointer;
  color: rgb(0, 162, 255);
}

.Body
{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

tbody > tr:hover
{
  background-color: lightgrey;
  cursor: pointer;
}


.root {
  width: 99%;
  margin: 0 auto;

}

.icon_size {
  font-size: 25px;
}


@media only screen and (max-width:640px){
  .Body {
    padding-top: 0;
  }

  .root {
    width: 100%;
  }
  .invoiced{
	color: white; 
	background-color: #00569C;
	padding: 5px 12px;
	text-align: center;
	width: 100%;
	border-radius:4px;
	margin-left:-20px;
	font-size: 14px;
}
.complete{
	color: white; 
	background-color: #388E3C; 
	padding: 5px 12px;
	text-align: center;
	width: 100%;
	border-radius:4px;
	margin-left:-20px;
	font-size: 14px;
}
.progress{
	margin-left:-20px;
	color: white; 
	background-color: #D61A0E;
	padding: 5px 12px; 
	text-align: center;
	width: 100%;
	border-radius:4px;
	font-size: 14px;
}

}